@font-face {
  font-family: 'Gotham';
  src: url('../asset/fonts/Gotham-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Obibok';
  src: url('../asset/fonts/Obibok-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../asset/fonts/Proxima.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}