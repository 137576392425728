.swiper-slide-active {
  margin: auto;
}

.swiper-slide {
  margin-right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 7);
}



@keyframes slideFade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#TentangKami {
  animation: slideFade 3s forwards
}

/* @media (min-width: 640px) { 
  .flex-wrap-justify {
    justify-content: space-between;
  }
} */

@media (max-width: 639px) {

  /* Adjust the max-width value to match the point at which wrapping occurs */
  .sm-flex-wrap-justify {
    justify-content: center;
  }
}

@media (max-width: 767px) {

  /* Adjust the max-width value to match the point at which wrapping occurs */
  .md-flex-wrap-justify {
    justify-content: center;
  }
}

.swiper-button-next::after {
  filter: drop-shadow(3px 3px 5px rgba(255, 255, 255, 0.7));
  width: 30px;
  height: 30px;
  object-fit: fill;
  background-size: fill;
  fill: #f40010 !important; 
}

.swiper-button-prev::after {
  filter: drop-shadow(3px 3px 5px rgba(255, 255, 255, 0.7));
  width: 30px;
  height: 30px;
  object-fit: fill;
  background-size: fill;
}

.swiper-coverflow, .swiper-wrapper {
  padding-top: 30px;
  padding-bottom: 130px;
}

:root {
  --swiper-theme-color: #f40010 !important;
}

.swiper-button-prev,
.swiper-button-next {
  top: 60% !important; /* Align vertically to the middle */
  width: 30px;
  height: 30px;
  padding: 5px; /* Add some padding for better click area */
  /* background-color: rgba(255, 255, 255, 0.5); /* Optional background */
  transform: translateY(-50%); /* Center vertically */
  fill: #f40010;
  object-fit: fill;
  background-size: fill;
}

@media (min-width: 960px) {
  .swiper-button-prev {
    left: 30% !important;
    /* Adjust this value to position the button */
  }

  .swiper-button-next {
    right: 30% !important;
    /* Adjust this value to position the button */
  }
}

@media (min-width: 540px) and (max-width: 959px) {
  .swiper-button-prev {
    left: 10% !important;
    /* Adjust this value to position the button */
  }

  .swiper-button-next {
    right: 10% !important;
    /* Adjust this value to position the button */
  }
}

@media (max-width: 539px) {
  .swiper-button-prev {
    left: 1% !important;
    /* Adjust this value to position the button */
  }

  .swiper-button-next {
    right: 1% !important;
    /* Adjust this value to position the button */
  }
}

div[id]:target::after, section[id]:target::before {
  content: "";
  display: block;
  height: 60px; /* Adjust as per the height of your navbar */
  margin-top: -60px; /* Should be negative of the height specified above */
  visibility: hidden;
}