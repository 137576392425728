.swiper-slide-active {
  margin: auto;
}

.swiper-slide {
  margin-right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 7);
}

.swiper-button-next::after {
  filter: drop-shadow(3px 3px 5px rgba(255, 255, 255, 0.7));
}

.swiper-button-prev::after {
  filter: drop-shadow(3px 3px 5px rgba(255, 255, 255, 0.7));
}

.swiper-coverflow {
  padding-top: 30px;
  padding-bottom: 50px;
}

.swiper-button-prev,
.swiper-button-next {
  top: 50%;
  /* Align vertically to the middle */
  width: auto;
  height: auto;
  padding: 5px;
  /* Add some padding for better click area */
  color: #f40010;
  /* Adjust the color as needed */
  /* background-color: rgba(255, 255, 255, 0.5); /* Optional background */
  transform: translateY(-50%);
  /* Center vertically */
}